<script>


export default {
  data() {
    return {
       user: JSON.parse(localStorage.getItem('user'))
    };
  },
};
</script>

<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row no-gutters">
        <div class="col-lg-5">
          <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
            <div class="w-100">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div>
                    <div class="text-center">
                      <div>
                        <a href="/" class="logo">
                          <img src="@/assets/images/company_logo.png" height="70" alt="logo" />
                        </a>
                      </div>

                      <h1 class="font-size-30 mt-4">Access Denied</h1>
                    </div>
                    <div class="mt-5 text-center">
                      <p>
                        © 2023 Wiremit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7" id="right-login">
          <div class="legend-login">
            <div class="text-center p-2 mt-5"> 
              <h3 class="font-size-3 text-muted"><i class="mdi mdi-lock mr-1"></i><br/>{{user.firstName}} do not have the access rights, permissions and priviledges to access this requested page.</h3>
            </div>

            <div class="text-center p-2 mt-5"> 
              <p class="font-size-24 text-muted">Your current access rights are limited to {{user.userType}}.</p>
            </div>

            <div class="mt-4 text-center">
              <router-link tag="a" to="/" class="text-muted font-size-18">
                <i class="mdi mdi-lock mr-1"></i> Go back to the home page.
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>